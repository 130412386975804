import React, { useEffect, useRef, useState } from "react";
import DatePicker, { DateObject } from "react-multi-date-picker";
import { createWinery } from "../../services/User/UserService";
import { notifyError, notifyTopRight } from "../common/Toaster";

import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";

import { imageUpload } from "../../services/AuthService";
import moment from "moment";
import crossIcon from "../../images/svg/cross-circle-svgrepo-com.svg";
import Loader from "../common/LoaderFullPage";
import { getRegionRestrictions, regionRestrictions } from "../common/ResionRestriction";

export default function CreateWinery(props) {
  const vendorId = localStorage.getItem("vendorId");
  //Modal box
  let errorsObj = {
    name: "",
    address: "",
    overview: "",
    price: "",
    guests: "",
    category: "",
    date: "",
    days: "",
    amenities: "",
    photo: "",
    startTime: "",
    endTime: "",
    openingHours: "",
    region: "",
    startDate: "",
    endDate: "",
  };
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    latitude: "",
    longitude: "",
    region: "",
    overview: "",
    guests: "",
    price: "",
    photo: [],
    category: "",
    cancelledDates: [],
    amenities: [],
    startDate: "",
    endDate: "",
  });

  console.log(formData, "formDataformData");


  const [errors, setErrors] = useState(errorsObj);
  const [newText, setNewText] = useState("");
  const [reference, setReference] = useState([]);
  const [currentEditIndex, setCurrentEditIndex] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [loader, setLoader] = useState(false);
  const format = "DD/MM/YYYY";
  const [dates, setDates] = useState([
    new DateObject().set({ day: null, format }),
  ]);
  const formattedDates = dates?.map((date, index) => {
    return date.format();
  });

  console.log(reference, "reference");


  console.log(formattedDates, "formattedDates");
  let zipCode = "";
  let city = "";

  const [formDataTime, setFormDataTime] = useState({
    Sunday: { startTime: "", endTime: "", timeSlots: [] },
    Monday: { startTime: "", endTime: "", timeSlots: [] },
    Tuesday: { startTime: "", endTime: "", timeSlots: [] },
    Wednesday: { startTime: "", endTime: "", timeSlots: [] },
    Thursday: { startTime: "", endTime: "", timeSlots: [] },
    Friday: { startTime: "", endTime: "", timeSlots: [] },
    Saturday: { startTime: "", endTime: "", timeSlots: [] },
  });
  const formDataTimeWithoutEmptyKeys = Object.keys(formDataTime).reduce(
    (acc, day) => {
      if (formDataTime[day].timeSlots.length > 0) {
        acc.push({ day, slots: formDataTime[day].timeSlots });
      }
      return acc;
    },
    []
  );

  const handleAddText = (e) => {
    e.preventDefault();
    const newArr = reference;
    if (newText.trim() && newText != "") {
      newArr.push(newText.trim());
    }
    setReference(newArr);
    setNewText("");
  };
  const handleUpdateText = () => {
    const updatedReference = [...reference];
    updatedReference[currentEditIndex] = newText;
    setReference(updatedReference);
    setNewText("");
    setIsEdit(false);
  };
  const handleEdit = (index, item) => {
    // Set newText state to the item being edited
    setNewText(item);
    // Set the currentEditIndex state to the index of the item being edited
    setCurrentEditIndex(index);
    // Set isEdit state to true to indicate that we're in edit mode
    setIsEdit(true);
  };
  const handleDelete = (index) => {
    const newReferences = [...reference];
    newReferences.splice(index, 1);
    setReference(newReferences);
  };
  const [uploadedImages, setUploadedImages] = useState([]); // State to store Uploades image URLs
  const [imagesData, setImagesData] = useState([null, null, null, null, null]); // State to store Data images (URLs)
  const [images, setImages] = useState([null, null, null, null, null]); // Local Preview State to store  images (URLs)


  const handleInputChange = (e) => {
    const selectedFiles = e.target.files;

    // Check if no files are selected
    if (selectedFiles.length === 0) {
      setErrors({ ...errors, photo: "No files selected" });
      return;
    }
    // Ensure the number of images doesn't exceed 5
    if (uploadedImages.length + selectedFiles.length > 5) {
      setErrors({ ...errors, photo: "You can only upload up to 5 images" });
      return;
    }

    const uploadPromises = [];
    // Loop through each selected file and upload
    for (let i = 0; i < selectedFiles.length; i++) {
      const selectedFile = selectedFiles[i];

      if (selectedFile) {
        const uploadPromise = imageUpload(selectedFile, vendorId)
          .then((response) => {
            return response.data?.data?.photo; // Return the image URL
          })
          .catch((error) => {
            console.log(error, "image upload error");
            return null; // Return null if an error occurs
          });
        uploadPromises.push(uploadPromise);
      }
    }

    // Wait for all promises to resolve
    Promise.all(uploadPromises).then((photoUrls) => {
      const validPhotos = photoUrls.filter((url) => url !== null); // Filter out any failed uploads

      // Update the state with the new images, ensuring no more than 5
      setUploadedImages((prevImages) => {
        const updatedImages = [...prevImages, ...validPhotos];
        setLoader(false);
        if (updatedImages.length > 5) {
          setErrors({ ...errors, photo: "You can only upload 5 images" });
          return prevImages; // Return previous state if more than 5
        }

        setErrors({ ...errors, photo: "" });
        return updatedImages;
      });
    });
  };

  const handleAddress = (place) => {
    geocodeByAddress(place.label)
      .then((results) => {

        if (results && results.length > 0) {
          const addressComponents = results[0]?.address_components || [];

          const city =
            addressComponents.find(
              (component) =>
                component.types.includes("locality") ||
                component.types.includes("administrative_area_level_2")
            )?.long_name || "";

          const zipCode =
            addressComponents.find((component) =>
              component.types.includes("postal_code")
            )?.long_name || "";

          return getLatLng(results[0]);
        } else {
          throw new Error("No results found for the provided address.");
        }
      })
      .then((res) => {
        console.log(res, "lat lng res");
        setFormData({
          ...formData,
          address: place?.label,
          latitude: res.lat,
          longitude: res.lng,
        });
        setErrors({ ...errors, address: "" });
      })
      .catch((error) => {
        console.error("Error getting address details:", error);
      });
  };

  const handleAddTime = (day) => {
    const { startTime, endTime, timeSlots } = formDataTime[day];
    const start = new Date(`1970-01-01T${startTime}:00`);
    const end = new Date(`1970-01-01T${endTime}:00`);

    // Validate time
    if (!startTime || !endTime) {
      setErrors({
        ...errors,
        [day]: "Both start and end times are required.",
      });
      notifyError("Both start and end times are required.");
      return;
    }

    if (end <= start) {
      setErrors({
        ...errors,
        [day]: "End time must be greater than start time.",
      });
      notifyError("End time must be greater than start time.");
      return;
    }

    const isDuplicate = timeSlots.some(
      (slot) => slot.startTime === startTime && slot.endTime === endTime
    );

    if (isDuplicate) {
      setErrors({
        ...errors,
        [day]: "This time slot already exists for the selected day.",
      });
      notifyError("This time slot already exists for the selected day.");
      return;
    }

    setFormDataTime((prevState) => ({
      ...prevState,
      [day]: {
        ...prevState[day],
        timeSlots: [...prevState[day].timeSlots, { startTime, endTime }],
        startTime: "", // Reset start time
        endTime: "", // Reset end time
      },
    }));
  };

  const handleDeleteTime = (day, index) => {
    const updatedTimeSlots = [...formDataTime[day].timeSlots];
    updatedTimeSlots.splice(index, 1);

    setFormDataTime((prevState) => ({
      ...prevState,
      [day]: {
        ...prevState[day],
        timeSlots: updatedTimeSlots,
      },
    }));
  };
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const API_DATE_FORMAT = "YYYY-MM-DD"; // Adjust this format based on your API requirements

  async function onPostData(e) {
    e.preventDefault();
    setLoader(true);
    let error = false;
    const errorObj = { ...errorsObj };

    // Add these validations along with your existing ones
    if (!formData.startDate) {
      errorObj.startDate = "Start date is required";
      error = true;
    }

    if (!formData.endDate) {
      errorObj.endDate = "End date is required";
      error = true;
    }

    if (formData.startDate && formData.endDate) {
      const start = moment(formData.startDate, format);
      const end = moment(formData.endDate, format);

      if (end.isSameOrBefore(start)) {
        errorObj.endDate = "End date must be after start date";
        error = true;
      }
    }

    // Validate form fields
    if (formData.name.trim() === "") {
      errorObj.name = "This field is required";
      error = true;
    }
    if (formData.address.trim() === "") {
      errorObj.address = "This field is required";
      error = true;
    }
    if (formData.guests.trim() === "") {
      errorObj.guests = "This field is required";
      error = true;
    }
    if (formData.price.trim() === "") {
      errorObj.price = "This field is required";
      error = true;
    }
    if (formData.category === "") {
      errorObj.category = "This field is required";
      error = true;
    }
    if (formData.overview.trim() === "") {
      errorObj.overview = "This field is required";
      error = true;
    }
    if (images.filter(img => img !== null).length !== 5) {
      errorObj.photo = "Please upload exactly 5 images";
      error = true;
    }
    const hasAnyTimeSlot = Object.values(formDataTime).some(day => day.timeSlots.length > 0);
    if (!hasAnyTimeSlot) {
      errorObj.openingHours = "Please select at least one time slot for any day of the week";
      notifyError('Please select at least one time slot for any day of the week');

      error = true;
    }

    // Add region validation
    if (formData.region.trim() === "") {
      errorObj.region = "This field is required";
      error = true;
    } else if (formData.region === "Greece") {
      errorObj.region = "Coming soon for Greece region";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      setLoader(false)
      return;
    }
    // Image uploading logic
    try {
      // First, validate that we have 5 images selected
      if (imagesData.filter(img => img !== null).length !== 5) {
        setErrors(prevErrors => ({
          ...prevErrors,
          photo: 'Please upload exactly 5 images'
        }));
        notifyError('Please select exactly 5 images');
        setLoader(false);
        return;
      }

      // Upload images with better error handling
      const uploadedImagesUrls = [];
      for (let i = 0; i < imagesData.length; i++) {
        const selectedFile = imagesData[i];
        if (!selectedFile) {
          continue;
        }

        try {
          const imageUrl = await uploadPromise(selectedFile, vendorId);
          if (!imageUrl) {
            throw new Error(`Failed to upload image ${i + 1}`);
          }
          uploadedImagesUrls.push(imageUrl);
        } catch (uploadError) {
          console.error(`Error uploading image ${i + 1}:`, uploadError);
          setErrors(prevErrors => ({
            ...prevErrors,
            photo: `Error uploading image ${i + 1}. Please try again.`
          }));
          notifyError(`Error uploading image ${i + 1}. Please try again.`);
          setLoader(false);
          return;
        }
      }

      // Validate uploaded images
      const finalValidImages = uploadedImagesUrls.filter(url => 
        url && 
        url !== "null" && 
        url !== "undefined" && 
        typeof url === 'string' && 
        url.trim() !== ''
      );

      if (finalValidImages.length !== 5) {
        console.error('Upload validation failed:', {
          total: uploadedImagesUrls.length,
          valid: finalValidImages.length,
          urls: uploadedImagesUrls
        });
        setErrors(prevErrors => ({
          ...prevErrors,
          photo: 'There was an error uploading some images. Please try again.'
        }));
        notifyError('There was an error uploading some images. Please try again.');
        setLoader(false);
        return;
      }

      // Format dates for API
      const formattedStartDate = formData.startDate ? 
        moment(formData.startDate, format).format(API_DATE_FORMAT) : 
        "";
      const formattedEndDate = formData.endDate ? 
        moment(formData.endDate, format).format(API_DATE_FORMAT) : 
        "";

      // Proceed with form submission using validated images
      const response = await createWinery({
        ...formData,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        photo: finalValidImages, // Use validated images array
        amenities: reference,
        cancelledDates: formattedDates,
        openingHours: formDataTimeWithoutEmptyKeys,
      });

      // Handle success
      setLoader(false);
      notifyTopRight(response.data?.message);
      props.history.push("/winery-management");
      console.log(response.data.data.users);
    } catch (error) {
      setLoader(false);
      notifyError(error.response?.data?.message || 'An error occurred. Please try again.');
      console.error('Form submission error:', error);
    }
  }

  // Example uploadPromise function
  const uploadPromise = async (selectedFile, vendorId) => {
    try {
      const response = await imageUpload(selectedFile, vendorId);
      return response.data?.data?.photo;
    } catch (error) {
      console.log(error, "image upload error");
      return null;
    }
  };



  function generateTimeOptions() {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 5) {
        const formattedHour = String(hour).padStart(2, "0");
        const formattedMinute = String(minute).padStart(2, "0");
        times.push(`${formattedHour}:${formattedMinute}`);
      }
    }
    return times;
  }

  const timeOptions = generateTimeOptions();

  function TimeInput({ label, value, onChange }) {
    return (
      <div className="w-100">
        <label>{label}</label>
        <input
          type="time"
          className="form-control "
          list="time-options"
          value={value}
          onChange={onChange}
        />
        <datalist id="time-options">
          {timeOptions?.map((time, index) => (
            <option key={index} value={time}></option>
          ))}
        </datalist>
      </div>
    );
  }


  const imageLocalUrl = async (file, index) => {
    // Simulate an image upload API function
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          data: {
            data: {
              photo: URL.createObjectURL(file), // Mocked uploaded image URL
            },
          },
        });
      }, 1000);
    });
  };
  const handleImageChange = async (e, index) => {
    const selectedFile = e.target.files[0]; // Get the selected file

    setImagesData((pre => {
      const updatedImagesData = [...pre];
      updatedImagesData[index] = selectedFile; // Set the image URL in the corresponding box
      return updatedImagesData;
    }))
    if (!selectedFile) {
      return;
    }
    // Immediately upload the image
    try {
      const response = await imageLocalUrl(selectedFile, vendorId);
      const uploadedImageUrl = response.data?.data?.photo;

      if (uploadedImageUrl) {
        setImages((prevImages) => {
          const updatedImages = [...prevImages];
          updatedImages[index] = uploadedImageUrl; // Set the image URL in the corresponding box
          return updatedImages;
        });
        setErrors({ ...errors, photo: "" });
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      setErrors({ ...errors, photo: "Error uploading image" });
    }
    setLoader(false);
  };

  const handleBoxClick = (index) => {
    document.getElementById(`imageInput${index}`).click();
  };

  const handleRemoveImage = (index) => {
    let imagesClone = [...images];
    imagesClone[index] = null;
    setImages(imagesClone);
  };

  return (
    <div className="card">
      <form>
        <div className="card-header">
          <h4 className="card-title fs-20">Add Details</h4>
        </div>
        <div className="card-body">
          <div className="add-contact-box">
            <div className="add-contact-content">
              <div className="field-wrapper">
                <div className="form-group mb-3 w-50">
                  <label className="text-black font-w500">Images</label>
                  <small>({images.filter(img => img !== null).length} out of 5)</small>

                  <div className="vendor-image-container">
                    {images.map((image, index) => (
                      <div className=" position-relative " style={{ zIndex: 0 }} >
                        {
                          images[index] !== null && <div onClick={() => { handleRemoveImage(index) }} className=" remove-image-btn  " > <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.8536 2.85355C13.0488 2.65829 13.0488 2.34171 12.8536 2.14645C12.6583 1.95118 12.3417 1.95118 12.1464 2.14645L7.5 6.79289L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L6.79289 7.5L2.14645 12.1464C1.95118 12.3417 1.95118 12.6583 2.14645 12.8536C2.34171 13.0488 2.65829 13.0488 2.85355 12.8536L7.5 8.20711L12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L8.20711 7.5L12.8536 2.85355Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>  </div>
                        }

                        <div
                          key={index}
                          className="vendor-image-box"
                          onClick={() => handleBoxClick(index)}
                        >

                          {image ? (
                            <img src={image} alt={`Box ${index + 1}`} className="vendor-image-img" />
                          ) : (
                            <p> <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.5 1H12.5C13.3284 1 14 1.67157 14 2.5V12.5C14 13.3284 13.3284 14 12.5 14H2.5C1.67157 14 1 13.3284 1 12.5V2.5C1 1.67157 1.67157 1 2.5 1ZM2.5 2C2.22386 2 2 2.22386 2 2.5V8.3636L3.6818 6.6818C3.76809 6.59551 3.88572 6.54797 4.00774 6.55007C4.12975 6.55216 4.24568 6.60372 4.32895 6.69293L7.87355 10.4901L10.6818 7.6818C10.8575 7.50607 11.1425 7.50607 11.3182 7.6818L13 9.3636V2.5C13 2.22386 12.7761 2 12.5 2H2.5ZM2 12.5V9.6364L3.98887 7.64753L7.5311 11.4421L8.94113 13H2.5C2.22386 13 2 12.7761 2 12.5ZM12.5 13H10.155L8.48336 11.153L11 8.6364L13 10.6364V12.5C13 12.7761 12.7761 13 12.5 13ZM6.64922 5.5C6.64922 5.03013 7.03013 4.64922 7.5 4.64922C7.96987 4.64922 8.35078 5.03013 8.35078 5.5C8.35078 5.96987 7.96987 6.35078 7.5 6.35078C7.03013 6.35078 6.64922 5.96987 6.64922 5.5ZM7.5 3.74922C6.53307 3.74922 5.74922 4.53307 5.74922 5.5C5.74922 6.46693 6.53307 7.25078 7.5 7.25078C8.46693 7.25078 9.25078 6.46693 9.25078 5.5C9.25078 4.53307 8.46693 3.74922 7.5 3.74922Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg> </p>
                          )}
                          <input
                            type="file"
                            id={`imageInput${index}`}
                            className="vendor-image-input"
                            accept="image/*"
                            onChange={(e) => handleImageChange(e, index)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>

                  {
                    errors.photo && (
                      <div className="text-danger fs-12">{errors.photo}</div>
                    )
                  }

                </div>
                <div className="form-group mb-3 w-50">
                  <label className="text-black font-w500">Name</label>
                  <div className="contact-name">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={formData.name}
                      onChange={(e) => {
                        setFormData({ ...formData, name: e.target.value });
                        setErrors({ ...errors, name: "" });
                      }}
                    />
                    <span className="validation-text"></span>
                    {errors.name && (
                      <div className="text-danger fs-12">{errors.name}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="field-wrapper">
                <div className="form-group mb-3 w-50">
                  <label className="text-black font-w500">Max amount of Guests per session</label>
                  <div className="contact-name">
                    <input
                      type="number"
                      className="form-control"
                      autocomplete="off"
                      name="department"
                      value={formData.guests}
                      min={1}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, '');
                        setFormData({
                          ...formData,
                          guests: value,
                        });
                        setErrors({ ...errors, guests: "" }); // Clear error when value changes
                      }}
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                    <span className="validation-text"></span>
                    {errors.guests && (
                      <div className="text-danger fs-12">{errors.guests}</div>
                    )}
                  </div>
                </div>
                <div className="form-group mb-3 w-50">
                  <label className="text-black font-w500">Price</label>
                  <div className="contact-name">
                    <input
                      type="number"
                      className="form-control"
                      autocomplete="off"
                      name="department"
                      value={formData.price}
                      min={0}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9.]/g, ''); // Allow digits and decimal point
                        // Prevent multiple decimal points
                        if ((value.match(/\./g) || []).length <= 1) {
                          setFormData({ ...formData, price: value });
                          setErrors({ ...errors, price: "" });
                        }
                      }}
                      onKeyPress={(e) => {
                        // Allow digits and decimal point
                        if (!/[0-9.]/.test(e.key)) {
                          e.preventDefault();
                        }
                        // Prevent multiple decimal points
                        if (e.key === '.' && String(formData.price).includes('.')) {
                          e.preventDefault();
                        }
                      }}
                    />
                    <span className="validation-text"></span>
                    {errors.price && (
                      <div className="text-danger fs-12">{errors.price}</div>
                    )}
                  </div>
                </div>
              </div>


              <div className="field-wrapper">

                <div className="form-group mb-3 w-100">
                  <label className="text-black font-w500">Region</label>
                  <div className="contact-name">
                    <select
                      className="form-control"
                      value={formData.region}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          region: e.target.value,
                        });
                        if (e.target.value === "Greece") {
                          setErrors({ ...errors, region: "Coming soon for Greece region" });
                        } else {
                          setErrors({ ...errors, region: "" });
                        }
                      }}
                    >
                      <option value="">Select...</option>
                      {regionRestrictions.map((item, index) => (
                        <option key={index} value={item.region}>{item.region}</option>
                      ))}
                    </select>
                    <span className="validation-text"></span>
                    {errors.region && (
                      <div className="text-danger fs-12">{errors.region}</div>
                    )}
                  </div>
                </div>

                <div className="form-group mb-3 w-100">
                  <label className="text-black font-w500">Address</label>
                  <div className="contact-name">
                    <GooglePlacesAutocomplete
                      apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                      selectProps={{
                        placeholder: "Enter Address",
                        inputValue: inputValue,
                        onInputChange: (value) => setInputValue(value),
                        onChange: handleAddress,
                        noOptionsMessage: () =>
                          inputValue ? "No options" : null, // Hide "No options" when input is empty
                        className: "form-control",
                      }}
                      autocompletionRequest={{
                        input: inputValue,
                        componentRestrictions: { country: ["CY", "GR"] }
                      }}
                    />
                    {errors.address && (
                      <div className="text-danger fs-12">{errors.address}</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="field-wrapper">
                {/* Start Date */}
                <div className="form-group mb-3 w-100">
                  <label className="text-black font-w500">Start Date</label>
                  <div className="contact-name">
                    <DatePicker
                      value={formData.startDate}
                      placeholder="Select Start Date"
                      onChange={(date) => {
                        setFormData({
                          ...formData,
                          startDate: date ? date.format(format) : "",
                        });
                        setErrors({ ...errors, startDate: "" });
                        // Clear blocked dates that are outside the new range
                        if (date) {
                          const startDate = moment(date.format(format), format);
                          const endDate = formData.endDate ? moment(formData.endDate, format) : null;

                          // Filter out dates that are before the new start date
                          const newDates = dates.filter(date => {
                            const currentDate = moment(date.format(), format);
                            return currentDate.isSameOrAfter(startDate) &&
                              (!endDate || currentDate.isSameOrBefore(endDate));
                          });
                          setDates(newDates);
                        }
                      }}
                      format={format}
                      calendarPosition="bottom-center"
                      minDate={new Date()}
                    />
                    {errors.startDate && (
                      <div className="text-danger fs-12">{errors.startDate}</div>
                    )}
                  </div>
                </div>
                <div className="form-group mb-3 w-100">
                  <label className="text-black font-w500">End Date</label>
                  <div className="contact-name">
                    <DatePicker
                      value={formData.endDate}
                      placeholder="Select End Date"
                      onChange={(date) => {
                        setFormData({
                          ...formData,
                          endDate: date ? date.format(format) : "",
                        });
                        setErrors({ ...errors, endDate: "" });
                        // Clear blocked dates that are outside the new range
                        if (date) {
                          const startDate = formData.startDate ? moment(formData.startDate, format) : null;
                          const endDate = moment(date.format(format), format);

                          // Filter out dates that are after the new end date
                          const newDates = dates.filter(date => {
                            const currentDate = moment(date.format(), format);
                            return (!startDate || currentDate.isSameOrAfter(startDate)) &&
                              currentDate.isSameOrBefore(endDate);
                          });
                          setDates(newDates);
                        }
                      }}
                      format={format}
                      calendarPosition="bottom-center"
                      minDate={formData.startDate ? moment(formData.startDate, format).toDate() : new Date()}
                    />
                    {errors.endDate && (
                      <div className="text-danger fs-12">{errors.endDate}</div>
                    )}
                  </div>
                </div>
             



             
              </div>
              <div className="field-wrapper">
                <div className="form-group mb-3 w-100 ">
                  <label className="text-black font-w500">Category</label>
                  <div className="contact-name">
                    <select
                      className="form-control"
                      value={formData.category}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          category: e.target.value,
                        });
                        setErrors({ ...errors, category: "" });
                      }}
                    >
                      <option >Select...</option>
                      <option>Wine Tasting</option>
                      <option>Wine Tours</option>
                      <option>Others</option>
                    </select>

                    <span className="validation-text"></span>
                    {errors.category && (
                      <div className="text-danger fs-12">{errors.category}</div>
                    )}
                  </div>

                </div>
                  {/* Blocked Dates */}
                  <div className="form-group mb-3 w-100">
                  <label className="text-black font-w500">
                    Blocked Dates
                  </label>
                  <div className="contact-name">
                    <DatePicker
                      value={dates}
                      placeholder="Select Blocked Dates"
                      onChange={setDates}
                      multiple
                      sort
                      format={format}
                      calendarPosition="bottom-center"
                      minDate={formData.startDate ? moment(formData.startDate, format).toDate() : new Date()}
                      maxDate={formData.endDate ? moment(formData.endDate, format).toDate() : undefined}
                      disabled={!formData.startDate || !formData.endDate}
                    />
                    <span className="validation-text"></span>
                   
                    {errors.date && (
                      <div className="text-danger fs-12">{errors.date}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group mb-3">
                <label className="text-black font-w500">Time Slots</label>
                <div>
                  {days.map((day) => (
                    <div
                      key={day}
                      className="contact-name d-flex  flex-column mb-3"
                      style={{ gap: "10px" }}
                    >
                      {/* Day Label and Inputs Container */}
                      <div
                        className="d-flex flex-column flex-md-row align-items-center  justify-content-between mb-2"
                        style={{ gap: "10px" }}
                      >
                        {/* Day Label */}
                        <div className=" time-slots-label marging_2 ">
                          <label className="text-black fw-bold">{day}</label>
                        </div>
                        {/* Time Inputs Container */}
                        <div className="d-flex flex-column flex-md-row w-100" style={{ gap: "10px" }}>
                          {/* Start Time Input */}
                          <div className="w-100">
                            <TimeInput
                              label="Start Time"
                              value={formDataTime[day].startTime}
                              onChange={(e) =>
                                setFormDataTime({
                                  ...formDataTime,
                                  [day]: {
                                    ...formDataTime[day],
                                    startTime: e.target.value,
                                  },
                                })
                              }
                            />
                          </div>
                          {/* End Time Input */}
                          <div className="w-100">
                            <TimeInput
                              label="End Time"
                              value={formDataTime[day].endTime}
                              onChange={(e) =>
                                setFormDataTime({
                                  ...formDataTime,
                                  [day]: {
                                    ...formDataTime[day],
                                    endTime: e.target.value,
                                  },
                                })
                              }
                            />
                          </div>
                          {/* Add Button */}
                          <div className="d-flex align-items-center">
                            <button
                              type="button"
                              className="btn marging_2 btn-primary w-100"
                              onClick={() => handleAddTime(day)}
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* Display Slots Below the Inputs */}
                      {formDataTime[day].timeSlots.length > 0 && (
                        <div className="time-slots-container">
                          {formDataTime[day].timeSlots.map((slot, index) => (
                            <button
                              type="button"
                              className="btn btn-outline-secondary mr-2 mb-2 time-slot-btn"
                              key={index}
                            >
                              <span className="time-slot-text">
                                {moment(slot.startTime, "HH:mm").format("hh:mm a")}{" "}
                                -{" "}
                                {moment(slot.endTime, "HH:mm").format("hh:mm a")}
                              </span>
                              <img
                                src={crossIcon}
                                width={20}
                                className="ml-2 delete-icon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeleteTime(day, index);
                                }}
                                alt="delete"
                              />
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div className="form-group mb-3">
                <label className="text-black font-w500">Amenities</label>
                {reference?.length > 0 && (
                  <div className=" mb-2">
                    <ul>
                      {reference?.map((item, index) => (
                        <div
                          className="d-flex justify-content-between fs-14"
                          key={index}
                        >
                          <li style={{ maxWidth: "400px" }}>{item} </li>
                          <div>
                            <i
                              className="flaticon-381-edit-1 pointer"
                              onClick={() => handleEdit(index, item)}
                            ></i>
                            <i
                              className="flaticon-381-trash-1 pointer ml-2"
                              onClick={() => handleDelete(index)}
                            ></i>
                          </div>
                        </div>
                      ))}
                    </ul>
                  </div>
                )}
                <div className="contact-name align-items-center d-flex">
                  <input
                    type="text"
                    className="form-control mr-2"
                    value={newText}
                    onChange={(e) => {
                      setNewText(e.target.value);
                      setErrors({ ...errors, amenities: "" });
                    }}
                  />
                  <button
                    type="button"
                    className="btn nice_btn  btn-primary btn-color"
                    onClick={isEdit ? handleUpdateText : handleAddText}
                  >
                    {isEdit ? "Update" : "Add"}
                  </button>
                </div>
              </div>

              <div className="form-group mb-0">
                <label className="text-black font-w500">Overview</label>
                <div className="contact-name">
                  <textarea
                    type="text"
                    rows={4}
                    className="form-control"
                    autocomplete="off"
                    name="department"
                    value={formData.overview}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        overview: e.target.value,
                      });
                      setErrors({ ...errors, overview: "" });
                    }}
                  />
                  <span className="validation-text"></span>
                  {errors.overview && (
                    <div className="text-danger fs-12">{errors.overview}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer text-right">
          <button
            type="button"
            className="btn btn-primary fs-14 py-2 px-3"
            onClick={onPostData}
          >
            Submit
          </button>
        </div>
      </form>
      {
        loader && <Loader />
      }
    </div>
  );
}
