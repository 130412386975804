import React, { useEffect, useState } from "react";
import { Dropdown, Card, Table, Badge, Col, Button } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../common/Spinner";
import Pagination from "../common/Pagination";
import { deleteWinery, getWinery } from "../../services/User/UserService";
import profile from "../../images/blank-profile-picture.webp";
import { notifyError, notifyTopRight } from "../common/Toaster";
import AddWinery from "../modal/AddWinery";
import EditWinery from "../modal/EditWinery";
import { useHistory } from "react-router-dom";
import ConfirmationModal from "../modal/ConfirmationModal";
import moment from "moment";
import BookWinery from "../modal/BookWinery";

export default function UserManagement(props) {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [edit, setEdit] = useState(false);
  const [oneData, setOneData] = useState(null);
  const [users, setUsers] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [listLength, setListlenght] = useState(0);
  const [search, setSearch] = useState("");
  const limit = 10;
  const [addModal, setAddModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [wineryId, setWineryId] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const imgUrl = "https://api.bookwineries.com/";

  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  ///function to fetch table data ///
  const getTableData = async () => {
    setLoader(true);
    try {
      const response = await getWinery(currentPage, limit, search);
      console.log(response.data, "data");
      setUsers(response.data.data?.winery);
      const total = response.data.data?.wineryCount;
      setPageCount(Math.ceil(total / limit));
      setListlenght(response.data.data?.wineryCount);
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoader(false);
    }
  };
  const onDelete = (id) => {
    setItemToDelete(id);
    setConfirmModal(true);
  };

  const handleConfirmDelete = async () => {
    setLoader(true);
    try {
      const response = await deleteWinery(itemToDelete);
      setConfirmModal(false);
      getTableData();

      notifyTopRight("Deleted Successfully!");
    } catch (error) {
      setConfirmModal(false);

      notifyError(error?.response?.data?.message);
      console.log(error, "error");
    } finally {
      setLoader(false);
    }
  };
  const onEdit = (item) => {
    setOneData(item);
    setEdit(true);
  };

  useEffect(() => {
    getTableData();
  }, [currentPage]);

  useEffect(() => {
    if (currentPage) {
      history.push({
        pathname: "/user-management",
        state: {
          page: currentPage,
        },
      });
    }
  }, [currentPage]);
  return (
    <div>
      <PageTitle activeMenu="Wineries List" motherMenu="Winery" />
      <Card>
        <Card.Header className="d-block">
          <div className="d-flex justify-content-between align-items-center">
            <div className="col-8"></div>
            {users?.length < 10 && (
              <button
                type="button"
                className="btn btn-primary px-3 py-2"
                onClick={() => history.push("/create-winery")}
                disabled={localStorage.getItem("isVerified") === "false"}
              >
                Add +
              </button>
            )}
          </div>
        </Card.Header>
        <Card.Body>
          {loader ? (
            <Spinner />
          ) : (
            <Table responsive className="mb-0">
              <thead className="text-black">
                <tr>
                  <th style={{ verticalAlign: 'top' }}>
                    <strong>Image</strong>
                  </th>
                  <th style={{ verticalAlign: 'top' }}>
                    <strong>NAME</strong>
                  </th>
                  <th style={{ verticalAlign: 'top' }}>
                    <strong>address</strong>
                  </th>
                  <th style={{ verticalAlign: 'top' }}>
                    <strong>category</strong>
                  </th>
                  <th style={{ verticalAlign: 'top' }}>
                    <strong>Guests</strong>
                  </th>
                  <th style={{ verticalAlign: 'top' }}>
                    <strong>Price</strong>
                  </th>
                  
                  <th style={{ verticalAlign: 'top' }}>
                    <strong>Created At</strong>
                  </th>
                  <th style={{ verticalAlign: 'top' }}>
                    <strong>Updated At</strong>
                  </th>
                  <th style={{ verticalAlign: 'top' }}>
                    <strong>Status</strong>
                  </th>
                  <th style={{ verticalAlign: 'top' }}>
                    <strong>ACTION</strong>
                  </th>
                </tr>
              </thead>
              <tbody className={users?.length === 1 ? "data_grid_body_for_one_data" : ""}>
                {users?.map((item, i) => (
                  <tr
                    key={i}
                    className="pointer"
                    onClick={() =>
                      history.push({
                        pathname: "/winery-details",
                        state: { data: item, page: currentPage },
                      })
                    }
                  >
                    <td>
                      <img
                        src={imgUrl + item?.photo[0]}
                        width={80}
                        height={80}
                        className="rounded"
                      />
                    </td>
                    <td>{item?.name}</td>
                    <td>{item?.address}</td>
                    <td>{item?.category}</td>
                    <td>{item?.guests}</td>
                    <td>€{item?.price}</td>
                    <td>
                      {moment(item?.createdAt).format("DD/MM/YYYY HH:mm")}
                    </td>
                    <td>
                      {moment(item?.updatedAt).format("DD/MM/YYYY HH:mm")}
                    </td>
                    <td>
                      {item?.isBlocked ? (
                        <Badge variant="danger light">Disabled</Badge>
                      ) : (
                        <Badge variant="success light">Enabled</Badge>
                      )}
                    </td>
                    <td
                      style={{ minWidth: "100px" }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Dropdown drop="up">
                        <Dropdown.Toggle
                          variant="warning light"
                          className="light sharp btn btn-warning i-false"
                        >
                          {svg1}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              setShowBookingModal(true);
                              setWineryId(item);
                            }}
                          >
                            Manual Booking
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => onEdit(item)}>
                            Edit
                          </Dropdown.Item>

                          <Dropdown.Item onClick={() => onDelete(item._id)}>
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          {users?.length === 0 && !loader ? (
            <div className="justify-content-center d-flex my-5 ">
              Sorry, Data Not Found!
            </div>
          ) : (
            ""
          )}
        </Card.Body>
      </Card>
      {addModal && (
        <AddWinery
          show={addModal}
          table={getTableData}
          onHide={() => setAddModal(false)}
        />
      )}
      {edit && (
        <EditWinery
          show={edit}
          table={getTableData}
          onHide={() => setEdit(false)}
          data={oneData}
        />
      )}
      <ConfirmationModal
        show={confirmModal}
        onHide={() => setConfirmModal(false)}
        onConfirm={handleConfirmDelete}
      />
      {showBookingModal && (
        <BookWinery
          show={showBookingModal}
          wineryId={wineryId}
          table={getTableData}
          onHide={() => setShowBookingModal(false)}
        />
      )}
    </div>
  );
}
